import React from 'react';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
} from '@lake-superior/ui-core';
import { useHistory } from 'react-router';
import { baseUrls } from '../../config/appsettings.json';

const LoginSelector = () => {
  const history = useHistory();

  return (
    <div className="use-ui-core">
      <Dialog
        isOpen={history.location.pathname.includes('/login')}
        style={DialogProps.DialogStyles.DEFAULT}
        size={DialogProps.DialogSizes.MEDIUM}
        closeButton
        buttons={[]}
        onCancel={() => {
          history.push('/');
        }}
        title="Choose your experience:"
      >
        <div className="navbar-option-container">
          <div className="navbar-option">
            <Button
              className="login"
              size={ButtonProps.ButtonSizes.SMALLFLEXIBLE}
              style={ButtonProps.ButtonStyles.PRIMARY}
              label="Associate"
              onClick={() => { window.location.href = baseUrls.associateShellUrl; }}
            />
          </div>
          <div className="navbar-option">
            <Button
              className="login"
              size={ButtonProps.ButtonSizes.SMALLFLEXIBLE}
              style={ButtonProps.ButtonStyles.PRIMARY}
              label="Branch"
              onClick={() => { window.location.href = baseUrls.branchShellUrl; }}
            />
          </div>
          <div className="navbar-option">
            <Button
              className="login"
              size={ButtonProps.ButtonSizes.SMALLFLEXIBLE}
              style={ButtonProps.ButtonStyles.PRIMARY}
              label="Customer"
              onClick={() => { window.location.href = baseUrls.customerShellUrl; }}
            />
          </div>
        </div>
      </Dialog>

    </div>
  );
};

export default LoginSelector;
