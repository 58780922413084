import React from 'react';
import { Route } from 'react-router-dom';
import LandingPage from '../LandingPage/LandingPage';

const ShellBody = () => (
  <div className="shell-body">
    <Route path="/" component={LandingPage} />
  </div>
);

export default ShellBody;
