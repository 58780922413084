import { tools } from '@lake-superior/core';
import React from 'react';
import { Redirect } from 'react-router';
import { getCachedCognitoCredentials } from './session.hook';

const withAuthRoute = (WrappedComponent) => {
  const Wrapped = (props) => {
    if (tools.isDefinedAndNotNullAndNotEmpty(getCachedCognitoCredentials())) {
      return (
        <WrappedComponent {...props} />
      );
    }

    return (<Redirect to="/" />);
  };
  return Wrapped;
};

export default withAuthRoute;
