export default {
  shell:
  {
    buttons: {
      Accept: 'shell.buttons.accept',
    },
    cookies: {
      main: 'shell.cookies.main',
      mainFindMore: 'shell.cookies.mainfindmore',
      browserConfig: 'shell.cookies.browserconfig',
      browserConfigHowto: 'shell.cookies.browserconfighowto',
    },
    topBanner: {
      main: 'shell.topbanner.main',
      mainMore: 'shell.topbanner.mainmore',
    },
    NAVBAR: {
      TITLE: 'shell.navbar.title',
      LOG_OUT: 'shell.navbar.log_out',
      LOGGING_OUT: 'shell.navbar.logging_out',
      MY_SHIFTS: 'shell.navbar.my_shifts',
      JOB_SEARCH: 'shell.navbar.job_search',
    },
    LANDING: {
      JOBSTACK: 'shell.landing.jobstack',
      BOX11_TITLE: 'shell.landing.box11_title',
      BOX11_CONTENT: 'shell.landing.box11_content',
      BOX11_BUTTON: 'shell.landing.box11_button',
      BOX11_SIGNUP: 'shell.landing.box11_signup',
      BOX11_SIGNUP_HELP: 'shell.landing.box11_signup_help',
      BOX21_TITLE: 'shell.landing.box21_title',
      BOX21_CONTENT: 'shell.landing.box21_content',
      BOX22_PAY: 'shell.landing.box22_pay',
      BOX22_DISTANCE: 'shell.landing.box22_distance',
      BOX22_BUTTON: 'shell.landing.box22_button',
      BOX22_MESSAGE_BOX_TITLE: 'shell.landing.box22_message_box_title',
      BOX21_NEW_FEATURE: 'shell.landing.box21_new_feature',
      BOX31_TITLE: 'shell.landing.box31_title',
      BOX31_CONTENT1: 'shell.landing.box31_content1',
      BOX31_CONTENT2: 'shell.landing.box31_content2',
      BOX31_FEATURE: 'shell.landing.box31_feature',
      FOOTER_CONTENT: 'shell.landing.footer_content',
    },
    GENERAL: {
      CLOSE: 'shell.general.close',
    },
    EXPERIENCE_SWITCHER: {
      INSTRUCTIONS: 'shell.experience_switcher.instructions',
      EXPERIENCE_SWITCHER: 'shell.experience_switcher.experience_switcher',
    },
    NOTIFICATIONS: {
      HEADER_TITLE: 'shell.notifications.header_title',
      MARK_ALL_AS_READ: 'shell.notifications.mark_all_as_read',
      MARK_ALL_AS_NOT_READ: 'shell.notifications.mark_all_as_not_read',
      DELETE_ALL: 'shell.notifications.delete_all',
      DELETE_ALL_READ: 'shell.notifications.delete_all_read',
      DELETE_ALL_NOT_READ: 'shell.notifications.delete_all_not_read',
      NO_NOTIFICATIONS: 'shell.notifications.no_notifications',
      NO_NOTIFICATIONS_READ: 'shell.notifications.no_notifications_read',
      NO_NOTIFICATIONS_NOT_READ: 'shell.notifications.no_notifications_not_read',
      CONFIRM_DELETE_NOTIFICATIONS: 'shell.notifications.confirm_delete_notifications',
      CONFIRM_DELETE_NOTIFICATIONS_READ: 'shell.notifications.confirm_delete_notifications_read',
      CONFIRM_DELETE_NOTIFICATIONS_NOT_READ: 'shell.notifications.confirm_delete_notifications_not_read',
      CONFIRM_DELETE_NOTIFICATION: 'shell.notifications.confirm_delete_notification',
      CONFIRM_DELETE_NOTIFICATIONS_TITLE: 'shell.notifications.confirm_delete_notifications_title',
      CONFIRM_DELETE_NOTIFICATION_TITLE: 'shell.notifications.confirm_delete_notification_title',
      ALL_NOTIFICATIONS_WERE_READ: 'shell.notifications.all_notifications_were_read',
      ALL_NOTIFICATIONS_WERE_NOT_READ: 'shell.notifications.all_notifications_were_not_read',
      ALL_NOTIFICATIONS_WERE_DELETED: 'shell.notifications.all_notifications_were_deleted',
      REFRESH: 'shell.notifications.refresh',
      LIST_END_REACHED: 'shell.notifications.list_end_reached',
      LIST_END_REACHED_READ: 'shell.notifications.list_end_reached_read',
      LIST_END_REACHED_NOT_READ: 'shell.notifications.list_end_reached_not_read',
      FILTER_ALL: 'shell.notifications.filter_all',
      FILTER_READ: 'shell.notifications.filter_read',
      FILTER_NOT_READ: 'shell.notifications.filter_not_read',
    },
  },
};
