import React from 'react';
import {
  Text,
  LabelTag,
  LabelTagProps,
  Icon,
} from '@lake-superior/ui-core';
import {
  faStar, faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import TranslateKeys from '../../locales/keys';
import bgImage1 from '../../assets/img/Landing/Workers.png';
import bgImage2 from '../../assets/img/Landing/Signup.png';
import jobStackLogoGrey from '../../assets/img/Landing/JobStack-logo-grey.png';
import LoginButton from '../Auth/LoginButton';

const LandingPage = () => (
  <>
    <div className="landing-page-container">
      {/* BOX 1 */}
      <div className="row box-1">
        <div className="noPadding col-xs-12 col-sm-6 col-md-6 col-lg-6 heroCopy">
          <div className="copyContent">
            <div className="landing-content">
              <div className="sub-title">
                <img src={jobStackLogoGrey} className="jobstack-logo" alt="JobStack Logo" />
              </div>
              <h1>
                <Text translateId={TranslateKeys.shell.LANDING.BOX11_TITLE} />
              </h1>
              <p>
                <Text translateId={TranslateKeys.shell.LANDING.BOX11_CONTENT} />
              </p>
              <LoginButton translateId={TranslateKeys.shell.LANDING.BOX11_BUTTON} />
              <div id="sign-up-container">
                <div className="small">
                  <Text translateId={TranslateKeys.shell.LANDING.BOX11_SIGNUP} />
                </div>
                <div className="small">
                  <Text href="https://www.peopleready.com/locations" translateId={TranslateKeys.shell.LANDING.BOX11_SIGNUP_HELP} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="noPadding col-xs-12 col-sm-6 col-md-6 col-lg-6 grayBackground">
          <div className="box12-image" />
        </div>
      </div>
      {/* BOX 2 */}
      <div className="container">
        <div className="row box-2 reverse">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className="landing-content box22_landing_content">
              <img src={bgImage1} className="box22_image" alt="People Ready workers" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className="landing-content shift-you-want">
              <h1>
                <Text translateId={TranslateKeys.shell.LANDING.BOX21_TITLE} />
              </h1>
              <p>
                <Text translateId={TranslateKeys.shell.LANDING.BOX21_CONTENT} />
              </p>

              <div className="use-ui-core">
                <LabelTag
                  translateId={TranslateKeys.shell.LANDING.BOX21_NEW_FEATURE}
                  type={LabelTagProps.LabelTagTypes.blue}
                  size={LabelTagProps.LabelTagSizes.MEDIUM}
                  icon={faStar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BOX 3 */}
      <div className="container">
        <div className="row box-3">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className="landing-content box31_landing_content">
              <img src={bgImage2} className="box31_image" alt="Young man talking to the phone" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className="landing-content">
              <h1>
                <Text translateId={TranslateKeys.shell.LANDING.BOX31_TITLE} />
              </h1>
              <p>
                <Text translateId={TranslateKeys.shell.LANDING.BOX31_CONTENT1} />
              </p>
              <p>
                <Text translateId={TranslateKeys.shell.LANDING.BOX31_CONTENT2} />
              </p>
              <div className="box32_feature">
                <Icon icon={faMapMarkerAlt} />
                <Text href="https://www.peopleready.com/locations" translateId={TranslateKeys.shell.LANDING.BOX31_FEATURE} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LandingPage;
