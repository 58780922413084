import React from 'react';
import { PropTypes } from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { esTranslation, enTranslation } from '@lake-superior/ui-core';
import { merge } from 'lodash';
import ShellEsLocale from './locales/translations/es.translations.json';
import ShellEnLocale from './locales/translations/en.translations.json';
import languages from './locales/languages';
import Smartlook from './Components/Smartlook';
import { smartlookconfig, environment } from './config/appsettings.json';
import ShellHeader from './Components/Shell/Header';
import ShellFooter from './Components/Shell/Footer';
import ShellBody from './Components/Shell/Body';
import LoginSelector from './Components/Auth/LoginSelector';

class App extends React.Component {
  constructor(props) {
    super(props);

    const defaultLanguage = window.localStorage.getItem('pr_preferredLanguageCode') || languages[0].code;
    const { addTranslationForLanguage, initialize } = this.props;

    initialize({
      languages,
      options: {
        renderToStaticMarkup,
        defaultLanguage,
      },
    });

    // Add the English and Spanish languages
    addTranslationForLanguage(merge(ShellEnLocale, enTranslation), 'en');
    addTranslationForLanguage(merge(ShellEsLocale, esTranslation), 'es');
  }

  componentDidMount() {
    if (smartlookconfig.enabled && environment.toLowerCase() === 'production') {
      Smartlook(smartlookconfig.projectKey);
    }
  }

  render = () => (
    <>
      <div className="shell-container">
        <LoginSelector />
        <ShellHeader />
        <ShellBody />
        <ShellFooter />
      </div>
    </>
  )
}

App.propTypes = {
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  addTranslationForLanguage: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
};

App.defaultProps = {
  activeLanguage: {
    code: 'en',
    name: 'English',
  },
};

export default connect()(withLocalize(App));
