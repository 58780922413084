import { tools, localStorageManager } from '@lake-superior/core';
import { isObject } from 'lodash';
import { localStorageKeys } from '../../config/appsettings.json';
import ExperiencesConfig from '../../config/ExperiencesConfig';

const getCachedCognitoCredentials = () => localStorageManager.getObjectLocalStorage('pr_session') || null;

const getUserRolesBasedOnSession = (session) => {
  if (tools.isPropertyDefinedAndNotNullAndNotEmpty(session, 'credentials.roles')) {
    const { roles } = session.credentials;
    return roles;
  }

  return [];
};

const getConfigBasedOnRole = (session) => {
  let config = {};
  const roles = getUserRolesBasedOnSession(session);
  if (roles.length > 0 && isObject(ExperiencesConfig)) {
    roles.sort().some((t) => {
      if (Object.prototype.hasOwnProperty.call(ExperiencesConfig, t)) {
        config = ExperiencesConfig[t];
        return true;
      }
      return false;
    });
  }

  return config;
};

const getUserRoles = () => {
  const session = getCachedCognitoCredentials();
  return getUserRolesBasedOnSession(session);
};

const executeLogOut = (auth, e) => {
  localStorageManager.removeFromLocalStorage(localStorageKeys.currentRoleConfig);
  auth.handleSignOut(e);
};

export {
  getCachedCognitoCredentials,
  getConfigBasedOnRole,
  getUserRoles,
  getUserRolesBasedOnSession,
  executeLogOut,
};
