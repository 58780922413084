import { localizeReducer } from 'react-localize-redux';
import { UICoreReducers } from '@lake-superior/ui-core';
import ShellReducer from '../ducks/shell';
import NotificationsReducer from '../ducks/notifications';
import TasksReducer from '../ducks/tasks';
import infiniteScroll from '../byPassedDucks/uicore.infinitescroll';

const computeUICoreReducers = {
  ...UICoreReducers,
  infiniteScroll,
};

const rootReducer = {
  localize: localizeReducer,
  shell: ShellReducer,
  notifications: NotificationsReducer,
  tasks: TasksReducer,
  ...computeUICoreReducers,
};

export default rootReducer;
