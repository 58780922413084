import { PropTypes } from 'prop-types';
import smartlookClient from 'smartlook-client';

const Smartlook = (projectKey) => {
  smartlookClient.init(projectKey);
};

Smartlook.propTypes = {
  projectKey: PropTypes.string,
};

Smartlook.defaultProps = {
  projectKey: '',
};

export default Smartlook;
