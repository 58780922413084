import React from 'react';
import className from 'classnames';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavBar from '../NavBar/NavBar';
import { shellHeaderClassesSelector } from '../../redux/ducks/shell';
import { hideHeaderAt } from '../../config/appsettings.json';

const ShellHeader = () => {
  const shellHeaderClasses = useSelector(shellHeaderClassesSelector);

  return (
    <Route
      render={({ location }) => (hideHeaderAt.includes(location.pathname)
        ? null
        : (
          <header className={className('shell-header', shellHeaderClasses)}>
            <NavBar />
          </header>
        ))}
    />
  );
};

export default ShellHeader;
