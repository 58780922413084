import { constants } from '@lake-superior/core';

const config = {
  'Reimagine-Associate-Role': {
    id: 1,
    redirecTo: '/Associate',
    displayName: 'Associate',
    loadOnBackground: [{ mfe: 'associate' }, { mfe: 'jobs' }, { mfe: 'branchassociate' }],
    experienceName: 'associate',
    notificationsTargetType: constants.NOTIFICATION_TARGET_TYPE.ASSOCIATE,
    notificationsEnabled: true,
    tasksEnabled: false,
    sessionType: constants.SESSION_TYPES.ASSOCIATE,
  },
  'Reimagine-Branch-Role': {
    id: 2,
    redirecTo: '/orders',
    displayName: 'Branch',
    loadOnBackground: [{ mfe: 'orders' }, { mfe: 'branchcustomer' }, { mfe: 'customers' }, { mfe: 'branchassociate' }, { mfe: 'taskmanagement' }],
    experienceName: 'orders',
    notificationsTargetType: constants.NOTIFICATION_TARGET_TYPE.BRANCH,
    notificationsEnabled: false,
    tasksEnabled: true,
    sessionType: constants.SESSION_TYPES.BRANCH,
  },
};

const getConfiguredSessionTypes = () => {
  const sessionTypes = [];
  const keys = Object.keys(config);

  keys.forEach((key) => {
    sessionTypes.push(config[key].sessionType);
  });

  return sessionTypes;
};

export default config;

export {
  getConfiguredSessionTypes,
};
