import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import className from 'classnames';
import LoginButton from '../Auth/LoginButton';
import JobStackLogo from '../../assets/img/Landing/jobstack.svg';
import PeopleReadyLogo from '../../assets/img/Landing/peopleready.svg';
import { getRouteToRedirectBasedOnSession } from '../Cognito/CognitoIntegration';
import { navBarClassesSelector, showNavBarSelector } from '../../redux/ducks/shell';

const NavBar = () => {
  const aditionalClasses = useSelector(navBarClassesSelector);
  const showNavBar = useSelector(showNavBarSelector);

  if (!showNavBar) {
    return null;
  }

  const classes = className('row middle-xs navbar-container', aditionalClasses);

  return (
    <div className={classes}>
      <div className="col-xs-2">
        <div className="use-ui-core navbar-title">
          <Link to={getRouteToRedirectBasedOnSession()} className="logo-container">
            <img src={PeopleReadyLogo} className="peopleready-logo hide-sm" alt="PeopleReady logo" />
            <div className="vertical-divider hide-sm" />
            <img src={JobStackLogo} className="jobstack-logo" alt="JobStack by PeopleReady logo" />
          </Link>
        </div>
      </div>
      <div className="end-xs col-xs-offset-6 col-md-offset-1 col-sm-offset-2 col-lg-offset-1 col-xs-4 col-sm-8 col-md-9 col-lg-9">
        <div className="box">
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
