import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { localStorageManager, tools } from '@lake-superior/core';
import { useSelector } from 'react-redux';
import {
  getCachedCognitoCredentials,
  getConfigBasedOnRole,
  getUserRoles,
} from './session.hook';
import { currentRoleConfigSelector } from '../../redux/ducks/shell';
import { localStorageKeys } from '../../config/appsettings.json';
import withAuthRoute from './withAuthRoute';

const getRedirectConfig = (session) => {
  if (tools.isDefinedAndNotNullAndNotEmpty(session)) {
    const config = getConfigBasedOnRole(session);

    if (tools.isDefinedAndNotNullAndNotEmpty(config)) {
      return config;
    }
  }
  return {};
};

const redirectOnPageReload = (history, currentRoleConfig) => {
  const session = getCachedCognitoCredentials();

  if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(session, 'credentials')) {
    return;
  }

  if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(currentRoleConfig, 'experienceName')) {
    history.push('/SelectExperience');
    return;
  }

  const { redirecTo } = getRedirectConfig(session);

  if (tools.isDefinedAndNotNullAndNotEmpty(redirecTo)) {
    if (redirecTo !== '/') {
      const currentLocation = window.location.href;

      if (!currentLocation.toLocaleLowerCase()
        .includes(redirecTo.toLocaleLowerCase())) {
        history.push(redirecTo);
      }
    }
  }
};

const getRouteToRedirectBasedOnSession = () => {
  const session = getCachedCognitoCredentials();
  const { redirecTo } = getRedirectConfig(session) || {};
  return redirecTo || '/';
};

const getRouteToRedirectAfterReconciliation = (cognitoAuthManager) => {
  const session = getCachedCognitoCredentials();
  const originalRedirecTo = cognitoAuthManager.getCognitoUserPreviousLocation() || '/';
  const { redirecTo } = getRedirectConfig(session);

  if (!localStorageManager.issetLocalStorage(localStorageKeys.currentRoleConfig)
  || !localStorageManager.issetLocalStorage(localStorageKeys.internalUserId)
  ) {
    return '/SelectExperience';
  }

  if (tools.isDefinedAndNotNullAndNotEmpty(redirecTo)) {
    return redirecTo;
  }

  return originalRedirecTo;
};

const CognitoIntegration = () => {
  const history = useHistory();
  const currentRoleConfig = useSelector(currentRoleConfigSelector);

  useEffect(() => {
    redirectOnPageReload(history, currentRoleConfig);
  }, [history, currentRoleConfig]);

  return null;
};

export {
  getRouteToRedirectBasedOnSession,
  getUserRoles,
  getRouteToRedirectAfterReconciliation,
};

export default withAuthRoute(CognitoIntegration);
