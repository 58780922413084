import React from 'react';
import {
  Button,
  ButtonProps,
} from '@lake-superior/ui-core';
import { useHistory } from 'react-router';

const GetLoginComponent = () => {
  const history = useHistory();
  return (
    <div className="use-ui-core">
      <Button
        className="login"
        onClick={() => { history.push('/login'); }}
        style={ButtonProps.ButtonStyles.PRIMARY}
        label="Log in"
      />
    </div>
  );
};

export default GetLoginComponent;
